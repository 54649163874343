import React from "react";
 import RightSection from "./right-section"

import LeftSection from "./left-section";
const CategoriesMain = ({ siteTitle, articleHeading, image, categoryID }) => {
  return (
    <div className="container-fluid main">
      <div className="row main-row pt-3">
        <LeftSection
          title={siteTitle}
          articleHeading={articleHeading}
          image={image}
        />
              <RightSection categoryID={categoryID}  image={image}/>
      </div>
    </div>
  );
};

export default CategoriesMain;
