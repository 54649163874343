import React, { useState } from "react"
import { UncontrolledCollapse, CardBody } from "reactstrap"

import Arrow from "../../images/down-arrow.svg"

const ReadOutSection = ({ articleHeading, description }) => {
  const [index, setIndex] = useState(false)
  const handleClick = () => (index ? "rotate(180deg)" : "rotate(0deg)")
  return (
    <div className="Read-out-main ">
      <div className="row">
        <div
          className="col read-out-col-main"
          onClick={() => setIndex(!index)}
          onTouchStart={() => setIndex(!index)}
          onKeyPress={() => setIndex(!index)}
          id="toggler"
        >
          <div className="col-10 col-xs-10 col-sm-10 col-md-10 read-out-main-section">
            <p className="read-out-heading">{articleHeading}</p>
          </div>
          <div className="col-2 col-xs-2 col-sm-2 col-md-2 btn down-arrow-div">
            <img
              style={{ transform: `${handleClick()}`, transition: "0.3s" }}
              className="down-arrow-logo"
              alt="down-arrow"
              src={Arrow}
            />
          </div>
        </div>
      </div>
      <div>
        <UncontrolledCollapse toggler="#toggler">
          <div className="row">
            <CardBody
              className="blog-post-content container-fluid ml-1 mr-1"
              style={{ fontSize: "0.8rem" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></CardBody>
          </div>
        </UncontrolledCollapse>
      </div>
    </div>
  )
}

export default ReadOutSection
