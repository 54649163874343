import React from "react"
// import Image from "../../image"

const Card = ({ img, title, content }) => {
  return (
    <div className="card mb-3">
      <div className="card-content">
        <div className="icon-container">
          <img className="card-icon" alt="card-icon" src={img} />
        </div>
        <p className="card-title">{title}</p>
        <p className="card-text">{content}</p>
      </div>
    </div>
  )
}

export default Card
