import React, { useState, useMemo } from "react"
import { Layout, SEO } from "../components"
import { graphql } from "gatsby"
import path from "path"

import HowItWorks from "../components/home/HowItWorksCategories"
import Main from "../components/categories/categories-main"
import ReadOutSection from "../components/categories/read-out-section"

const Categories = ({ data }) => {
  const [CatData, setCatData] = useState({
    title: "",
    articleHeading: "",
    id: 0,
    html: "",
    image: "",
    categoryID: "",
  })

  useMemo(() => {
    const fetchData = async () => {
      const {
        title,
        articleHeading,
        image,
        categoryName,
        categoryID,
      } = await data?.markdownRemark?.frontmatter
      const { id, html } = await data?.markdownRemark
      setCatData({
        title,
        articleHeading,
        id,
        html,
        image,
        categoryName,
        categoryID,
      })
    }
    fetchData()
  }, [data])

  const {
    title,
    articleHeading,
    id,
    html,
    image,
    categoryName,
    categoryID,
  } = CatData
  return (
    <Layout>
      <SEO title={categoryName} />
      <Main key={id} siteTitle={title} image={image} categoryID={categoryID} />
      <HowItWorks />
      <div className="container-fluid">
        <ReadOutSection
          key={id}
          articleHeading={articleHeading}
          description={html}
        />
      </div>
    </Layout>
  )
}

export const query =
  (graphql`
    query getImages($path: String) {
      markdownRemark(frontmatter: { path: { glob: $path } }) {
        frontmatter {
          categoryID
          title
          path
          categoryName
          articleHeading
          image
        }
        html
        id
      }
    }
  `,
  { fileName: { path } })

export default Categories
