import React from "react"
import VectorSub from "../../images/Vector-sub.svg"
import GroupSub from "../../images/Group-sub.svg"
import Image from ".././image"

const RightSection = ({ categoryID ,image}) => {


  return (
    <>
      <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 right-section">
              <div className="card right-card d-flex  m-auto h-auto" >
                   <Image className="controll-logo" src={image}></Image>
        </div>
      </div>
      <div className="px-2 mt-3 px-sm-5 d-flex flex-column d-md-none">
        <div className="d-flex">
          <img className=" ml-0 mr-2" alt="vecto-sub" src={VectorSub} />
          <p className="mb-0">
            <small>
              Compare Multiple Rates, so you know you’re getting the best deal.
            </small>
          </p>
        </div>
        <div className="d-flex">
          <img className=" ml-0 mr-2" alt="group-sub" src={GroupSub} />
          <p className="mb-0">
            <small>
              Compare quotes on essential services and equipment for your home
              or business.
            </small>
          </p>
        </div>
      </div>
    </>
  )
}

export default RightSection
